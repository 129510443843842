import React, { useEffect, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import CloseIcon from "../../assets/images/svg/close.svg";
import DeleteRedIcon from "../../assets/images/svg/deleteRedIcon.svg";
import { txt } from "../../common/context";
import { makeStyles, Radio } from '@material-ui/core';
import clsx from 'clsx';
import { DeleteButton, LightButton, PrimaryButton } from './CustomButtons';
import MatchCard from './MatchCard';
import { BASE_URL, sendHttpRequest } from '../../common/Common';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ShowToast } from './ToastMessage';

const DeleteMatchBottomSheet = ({
    isOpen,
    onDismiss,
    selectedMatch
}) => {
    const history = useHistory();

    const [waitForApiRes, setWaitForApiRes] = useState(false);

    const handleDeleteMatch = () => {
        setWaitForApiRes(true);
        sendHttpRequest("DELETE", `${BASE_URL}/api/match/${selectedMatch._id}`)
            .then(async (res) => {
                ShowToast(selectedMatch?.matchName, {
                    position: "top-center",
                    type: "error",
                });
                // console.log("handleDeleteMatch res : ", res)
                onDismiss();
                history.push('/home');
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.error("handleDeleteMatch error :", error.response.data.message);
            });
    }

    return (
        <BottomSheet
            open={isOpen}
            onDismiss={onDismiss}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="bottomSheetContainer">
                <div className="header">
                    <img src={DeleteRedIcon} alt="" />
                    <img
                        src={CloseIcon}
                        alt="Close"
                        className="close-icon"
                        onClick={onDismiss}
                    />
                </div>
                <div className="content">
                    <p className="sheet-header-text">{txt.are_you_sure_you_want_to_delete_this_match}</p>

                    <MatchCard data={selectedMatch} isModal={true} />

                    <div className="button-container" style={{ marginTop: '70px' }}>

                        <DeleteButton disabled={waitForApiRes} onClick={handleDeleteMatch}>
                            {txt.yes}
                        </DeleteButton>

                        <LightButton disabled={waitForApiRes} onClick={() => onDismiss()}>
                            {txt.no}
                        </LightButton>
                    </div>
                </div>
            </div>
        </BottomSheet>
    )
}

export default DeleteMatchBottomSheet