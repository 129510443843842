import React from 'react'

function TotalNumberWithTitle({
    number,
    numberSize,
    title,
    titleSize,
    backgroundColor,
    boxShadow,
}) {

    return (
        <div>
            <div style={{ minWidth: '80px', background: backgroundColor ? backgroundColor : 'var(--bg3)', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '6px 12px', borderRadius: '15px', boxShadow: boxShadow, }}>
                <h1 className={`${numberSize ? numberSize : 'f3239400'}`}>{number}</h1>
                <p className={`${titleSize ? titleSize : 'f1419400'}`}>{title}</p>
            </div>
        </div>
    )
}

export default TotalNumberWithTitle