import React, { Fragment, useEffect } from 'react'
import Header from '../CustomMUI/Header'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import PlayerProfileCard from '../CustomMUI/PlayerProfileCard';
import { useState } from 'react';
import { txt } from '../../common/context';
import { Tab, Tabs } from '@material-ui/core';
import MatchCard from '../CustomMUI/MatchCard';
import { BASE_URL, sendHttpRequest } from '../../common/Common';
import TotalNumberWithTitle from './TotalNumberWithTitleCard';
import { CustomActiveDiactiveButtonWithShadow } from '../CustomMUI/CustomButtons';

function PlayerCricketHistoryProfile(props) {
    const location = useLocation();

    // const { pageTitle, userInfo } = location.state || { pageTitle: "", };
    const locationState = location.state || { pageTitle: props.pageTitle, userInfo: props.userInfo };

    // const [locationState, setLocationState] = useState();
    const [selectedTab, setSelectedTab] = useState(1);
    const [totalMatches, setTotalMatches] = useState(0);
    const [totalRuns, setTotalRuns] = useState(0);
    const [totalWickets, setTotalWickets] = useState(0);
    const [playerMatches, setPlayerMatches] = useState([]);
    const [battingStat, setBattingStat] = useState([]);
    const [bowlingStat, setBowlingStat] = useState([]);
    const [fieldingStat, setFieldingStat] = useState([]);
    const [captainStat, setCaptainStat] = useState([]);

    const [statisticsBtnArr, setStatisticsBtnArr] = useState([
        txt.Batting,
        txt.Bowling,
        txt.Fielding,
        txt.Captain,
    ]);

    const [activeStatisticsBtn, setActiveStatisticsBtn] = useState(txt.Batting);

    useEffect(() => {
        getAllMatches();
        getTotalRuns();
        getTotalWickets();
        getBattingData();
        getBowlingStatData();
    }, [])

    const getBattingData = () => {
        if (locationState?.userInfo?._id) {
            sendHttpRequest(
                "GET",
                `${BASE_URL}/api/player/battingStats/${locationState.userInfo._id}`
            )
                .then(async (res) => {
                    if (res.data.data) {
                        setBattingStat(res.data.data)
                    }
                })
                .catch((error) => {
                    // toast.error(error.response.data.message);
                });
        }
    }

    const getBowlingStatData = () => {
        if (locationState?.userInfo?._id) {
            sendHttpRequest(
                "GET",
                `${BASE_URL}/api/player/bowlingStats/${locationState.userInfo._id}`
            )
                .then(async (res) => {
                    if (res.data.data) {
                        setBowlingStat(res.data.data)
                    }
                })
                .catch((error) => {
                    // toast.error(error.response.data.message);
                });
        }
    }

    const getTotalRuns = () => {
        if (locationState?.userInfo?._id) {
            sendHttpRequest(
                "GET",
                `${BASE_URL}/api/player/totalRuns/${locationState.userInfo._id}`
            )
                .then(async (res) => {
                    if (res.data.data) {
                        setTotalRuns(res.data.data.totalRuns)
                    }
                })
                .catch((error) => {
                    // toast.error(error.response.data.message);
                });
        }
    }

    const getAllMatches = () => {

        if (locationState?.userInfo?._id) {
            sendHttpRequest(
                "GET",
                `${BASE_URL}/api/match/user/${locationState.userInfo._id}`
            )
                .then(async (res) => {
                    if (res.data.data) {
                        setPlayerMatches(res.data.data);
                        setTotalMatches(res.data.data.length)
                    }
                })
                .catch((error) => {
                    // toast.error(error.response.data.message);
                });
        }
    };

    const getTotalWickets = () => {
        if (locationState?.userInfo?._id) {
            sendHttpRequest(
                "GET",
                `${BASE_URL}/api/player/totalWickets/${locationState.userInfo._id}`
            )
                .then(async (res) => {
                    if (res.data.data) {
                        setPlayerMatches(res.data.data);
                        setTotalMatches(res.data.data.length)
                    }
                })
                .catch((error) => {
                    // toast.error(error.response.data.message);
                });
        }
    };

    return (
        <div className='pageMainContainer' style={{ padding: props.pageTitle && 0 }}>
            <Header title={locationState?.pageTitle} />

            <div style={{ display: props.pageTitle && 'none'  }}>

                <div style={{ padding: '20px' }}>
                    {
                        locationState?.userInfo &&
                        <PlayerProfileCard userInfo={locationState?.userInfo} />
                    }
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: '10px' , marginBottom : '20px' }}>
                    <TotalNumberWithTitle number={totalMatches || '00'} title={txt.Matches} />
                    <TotalNumberWithTitle number={totalRuns || '00'} title={txt.Runs} />
                    <TotalNumberWithTitle number={'00'} title={txt.Wickets} />
                </div>

            </div>
            <div style={{ background: 'var(--card-1-bg)'}}>
                <Tabs
                    value={selectedTab}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                    onChange={(e, newTab) => setSelectedTab(newTab)} >
                    <Tab label={txt.Matches} />
                    <Tab label={txt.Statistics} />
                    <Tab label={txt.Teams} />
                </Tabs>
            </div>

            <div style={{ flex: 1, overflowY: 'scroll', position: 'relative' }}>
                {
                    selectedTab === 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
                            {

                                playerMatches?.map(
                                    (match, index) => (
                                        (<MatchCard data={match} key={index} hideDeleteEditBtn={true} />)
                                    )
                                )
                            }
                        </div>
                    ) : selectedTab === 1 ? (
                        <>
                            <div style={{ display: 'flex', padding: '14px 0px ', justifyContent: 'center', gap: '15px', position: 'sticky', top: 0, background: '#fff' }}>
                                {
                                    statisticsBtnArr.map((title, idx) => (
                                        <CustomActiveDiactiveButtonWithShadow
                                            key={idx}
                                            title={title}
                                            isActive={activeStatisticsBtn === title}
                                            onClick={() => {
                                                setActiveStatisticsBtn(title)
                                            }}
                                        />
                                    ))
                                }
                            </div>
                            <div style={{ padding: '0 20px 20px 20px' }}>
                                {
                                    activeStatisticsBtn === txt.Batting ?

                                        battingStat?.map((items, idx) => (
                                            <React.Fragment key={idx}>
                                                <p className='f1520600' style={{ padding: '8px 0' }}>{items?.title}</p>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                                                    {
                                                        items?.data?.map(({ number, title }, idx2) => (
                                                            <TotalNumberWithTitle
                                                                key={idx2}
                                                                number={number}
                                                                numberSize={'f2632500'}
                                                                title={title}
                                                                titleSize={'f1113400'}
                                                                backgroundColor={'var(--bg4)'}
                                                                boxShadow={'var(--shadow2)'}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </React.Fragment>
                                        ))
                                        :
                                        bowlingStat?.map((items, idx) => (
                                            <>
                                                <p className='f1520600' style={{ padding: '8px 0' }}>{items?.title}</p>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                                                    {
                                                        items?.data?.map(({ number, title }) => (
                                                            <TotalNumberWithTitle
                                                                number={number}
                                                                numberSize={'f2632500'}
                                                                title={title}
                                                                titleSize={'f1113400'}
                                                                backgroundColor={'var(--bg4)'}
                                                                boxShadow={'var(--shadow2)'}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        ))
                                }
                            </div>
                        </>
                    ) : selectedTab === 2 ? (
                        <h2 style={{textAlign : 'center'}}>Coming soon</h2>
                    ) : null
                }
            </div>

        </div>
    )
}

export default PlayerCricketHistoryProfile