import React, { useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import CloseIcon from "../../assets/images/svg/close.svg";
import RightArrow from "../../assets/images/svg/rightArrow.svg";
import { txt } from "../../common/context";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import clsx from 'clsx';
import { LightButton, PrimaryButton } from './CustomButtons';


const CustomMatchTypeBottomSheet = ({
    isOpen,
    onDismiss,
    setMatchType
}) => {

    const useStyles = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: '50%',
            width: 40,
            height: 40,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 40,
                height: 40,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
    });

    const classes = useStyles();
    const [selectedMatch, setSelectedMatch] = useState();

    const matchType = [
        {
            type: 'Limited overs',
            player: 'Min number of players 5',
        },
        {
            type: 'Indoor',
            player: 'Min number of players 5'
        },
        {
            type: 'Test match',
            player: 'Min number of players 5'
        },
    ]

    return (
        <BottomSheet
            open={isOpen}
            onDismiss={onDismiss}
        >
            <div className='bottomSheetContainer'>
                <div className="header">
                    <img src={RightArrow} alt="Party" />
                    <img
                        src={CloseIcon}
                        alt="Close"
                        className="close-icon"
                        onClick={onDismiss}
                    />
                </div>
                <div className="content">
                    <p className="sheet-header-text">{txt.match_type}</p>
                    <p className="sheet-description-text">
                        {txt.select_the_desired_match_type}
                    </p>

                    <div>
                        {
                            matchType.map((match, idx) => (
                                <div key={idx} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}>
                                    <Radio
                                        disableRipple
                                        checked={selectedMatch === idx}
                                        color="default"
                                        value={idx}
                                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                        icon={<span className={classes.icon} />}
                                        onChange={(event) => {
                                            setSelectedMatch(idx)
                                        }}
                                    />
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start'
                                    }}>
                                        <p style={{ fontSize: "20px", fontWeight: '600', margin: 0 }}>{match.type}</p>
                                        <p style={{ fontSize: "16px", color: "#6f6c6f", margin: 0 }}>{match.player}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="button-container">
                        <PrimaryButton onClick={() => {
                            if (typeof selectedMatch === "number") {
                                onDismiss();
                                setMatchType(matchType[selectedMatch])
                            }
                        }}>
                            {txt.confirm}
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </BottomSheet>
    )
}

export default CustomMatchTypeBottomSheet