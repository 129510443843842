import React, { useEffect } from 'react'
import ProfilPlacholder from "../../assets/images/svg/profil_placeholder.svg";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


function PlayerProfileCard({ userInfo }) {

    const { pathname } = useLocation();
    const isProfile = pathname.startsWith('/my-profile');
    const isPlayerCricketProfile = pathname.startsWith('/player-cricket-profile');

    return (
        <div className='flex g20'>
            <img
                src={userInfo?.profilePhoto || ProfilPlacholder}
                alt="Profile Placeholder"
                className="profile-pic"
                style={{ cursor: "pointer" }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <p className='f1823400'>{userInfo?.firstName} {userInfo?.lastName}</p>
                {
                    isProfile &&
                    <>
                        <p className='f1520400'>+{userInfo?.contactNo}</p>
                        <p className='f1113400'>{userInfo?.email}</p>
                    </>
                }

                {
                    isPlayerCricketProfile &&
                    <>
                        <p className='f1520400'>{userInfo?.location}</p>
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                            <p className='f1520400'>{userInfo?.playingRole}</p>
                            <span style={{ borderLeft: '1px solid #000', height: '15px', margin: '0 5px' }}></span>
                            <p className='f1520400'>{userInfo?.battingStyle}</p>
                        </div>
                        <p className='f1520400'>{userInfo?.bowlingStyle}</p>
                    </>
                }
            </div>
        </div>
    )
}

export default PlayerProfileCard