import React from "react";
import Header from "../CustomMUI/Header";
import PlayerCricketHistoryProfile from "../Player/PlayerCricketHistoryProfile";
import { useAuth } from "../../context/AuthContext";

const MyCricket = () => {
  const { userInfo } = useAuth();
  return (
    <div className="pageMainContainer">
      {/* <Header title={'My Cricket'} /> */}
      <PlayerCricketHistoryProfile pageTitle={'My Cricket'} userInfo={userInfo} />
    </div>
  );
};
export default MyCricket;
