import { Drawer, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CloseIcon from "../../assets/images/svg/close.svg";

import { PrimaryButton } from './CustomButtons';
import { txt } from '../../common/context';

function BottomSheet({
    open,
    hide,
    headerImg,
    headerImgStyle,
    headerTitle,
    headerTitleStyle,
    headerDesc,
    headerDescStyle,
    bodyTypeRadio,
    radioGroupName,
    radioDataList,
    onChangeSelectValue,
    selectedRadioValue,
}) {

    const [selectedValue, setSelectedValue] = useState();

    useEffect(() => {
        const selectedValue = selectedRadioValue ? selectedRadioValue() : null;

        if (selectedValue) {
            setSelectedValue(selectedValue);
        } else {
            if (radioDataList?.[0]) {
                setSelectedValue(radioDataList[0]);
            }
        }
    }, [radioDataList, selectedRadioValue])

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <Drawer
            anchor='bottom'
            open={open}
            onClose={hide}
        >
            <div className='p20'>

                <div className='bottomSheetHeader' style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <img src={headerImg} alt="" style={{ ...headerImgStyle }} />
                        <img onClick={hide} src={CloseIcon} alt="" />
                    </div>
                    <p
                        className='f2025400'
                        style={{
                            color: 'var(--color2)',
                            marginBottom: '5px',
                            ...headerTitleStyle
                        }}
                    >
                        {headerTitle}
                    </p>
                    <p
                        className='f1520400'
                        style={{
                            color: 'var(--color3)',
                            ...headerDescStyle
                        }}
                    >
                        {headerDesc}
                    </p>
                </div>

                <div className='bottomSheetBody'>
                    {
                        bodyTypeRadio &&

                        <RadioGroup
                            name={radioGroupName}
                            value={selectedValue}
                            onChange={handleChange}
                            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                        >
                            {
                                radioDataList?.map((role, idx) =>
                                    <div key={idx} style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                        <Radio
                                            checked={selectedValue === role}
                                            onChange={handleChange}
                                            value={role}
                                            name={radioGroupName}
                                        />
                                        <span className='f1520600'>{role}</span>
                                    </div>
                                )
                            }
                        </RadioGroup>
                    }
                </div>

                <div className='bottomSheetFooter' style={{ marginTop: '20px' }}>
                    <PrimaryButton onClick={() => {
                        if (onChangeSelectValue !== undefined) {
                            onChangeSelectValue(selectedValue)
                        }
                    }}>
                        {txt.continue}
                    </PrimaryButton>
                </div>
            </div>
        </Drawer>
    )
}

export default BottomSheet