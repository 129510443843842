import React, { useState } from 'react'
import Header from '../CustomMUI/Header'
import ProfilPlacholder from "../../assets/images/svg/profil_placeholder.svg";
import RightArrowImg from "../../assets/images/svg/rightSideArrow.svg";
import editIcon from "../../assets/images/svg/editIcon.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomButtonWithIcon, CustomCardButton, CustomSmallButton } from '../CustomMUI/CustomSmallButton';
import { Tab, Tabs } from '@material-ui/core';
import { txt } from '../../common/context';
import { CustomIconButton } from '../CustomMUI/CustomButtons';
import { useAuth } from '../../context/AuthContext';
import PlayerProfileCard from '../CustomMUI/PlayerProfileCard';

function MyProfile() {
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState(0);
    const { userInfo } = useAuth()
    const [userData, setUserData] = useState([
        { [txt.Contact_Number]: userInfo?.contactNo },
        { [txt.Gender]: userInfo?.gender },
        { [txt.Playing_Role]: userInfo?.playingRole },
        { [txt.Batting_Style]: userInfo?.battingStyle },
        { [txt.Bowling_Style]: userInfo?.bowlingStyle },
        { [txt.Date_of_Birth]: userInfo?.birthDate },
        { [txt.email_address]: userInfo?.email },
    ])
    return (
        <div className='pageMainContainer'>
            <Header title={txt.My_Profile} />


            <div className='p20' style={{ display: 'flex', flexDirection: 'column'}}>

                <PlayerProfileCard userInfo={userInfo} />

                <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
                    <CustomButtonWithIcon
                        title={'Go Pro'}
                        endIcon={<img src={RightArrowImg} alt="" />}
                    />
                    <CustomButtonWithIcon
                        onClick={() => history.push("/player-cricket-profile", { pageTitle: "My Player Profile", userInfo: userInfo })}
                        title={'My Player Profile'}
                        endIcon={<img src={RightArrowImg} alt="" />}
                    />
                </div>

            </div>

            <div style={{ background: 'var(--card-1-bg)' }}>
                <Tabs
                    value={selectedTab}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    onChange={(e, newTab) => setSelectedTab(newTab)} >
                    <Tab label={txt.Personal_Details} />
                    <Tab label={txt.Preferences} />
                    <Tab label={txt.Payments} />
                    <Tab label={txt.Connections} />
                </Tabs>
            </div>

            <div style={{ flex: '1', background: 'var(--card-1-bg)', marginTop: '20px', padding: '10px 20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className='f1520600'> {txt.My_Profile} </p>
                    <CustomIconButton
                        onClick={() => history.push('/edit-my-profile')}
                        style={{ borderRadius: '5px', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        children={<img src={editIcon} alt="" />}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '10px' }}>

                    {
                        userData.map((item, index) => {
                            const key = Object.keys(item)[0];
                            const value = item[key];
                            if (value) {
                                return (
                                    <div key={index}>
                                        <p className='f1419400' style={{ color: 'var(--color1)' }}>{key}</p>
                                        <p className='f1520400' style={{ color: 'var(--netural-color-900)' }}>{value}</p>
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default MyProfile